<template>
  <b-form v-if="docData != null" @submit.stop.prevent="onSubmit">
    <b-form-group label="Идентификатор">
      <b-form-input v-model="$v.docData.id.$model"
                    :state="$v.docData.id.$dirty ? !$v.docData.id.$error : null"
                    :disabled="!creating || disabled || !isAdmin"></b-form-input>
      <b-button class="mt-2" @click="generateDocId" type="button" v-if="creating && (!disabled)">Сгенерировать
      </b-button>
    </b-form-group>
    <b-form-group label="Название">
      <b-form-input v-model="$v.docData.name.$model"
                    :state="$v.docData.name.$dirty ? !$v.docData.name.$error : null"
                    :disabled="disabled || !isAdmin"
      ></b-form-input>
    </b-form-group>
    <b-form-group label="Цена">
      <b-form-input type="number" step="0.01" v-model="$v.docData.price.$model"
                    :state="$v.docData.price.$dirty ? !$v.docData.price.$error : null"
                    :disabled="disabled || !isAdmin"
      ></b-form-input>
    </b-form-group>
    <b-form-group label="Описание">
      <b-form-textarea v-model="$v.docData.description.$model"
                       :state="$v.docData.description.$dirty ? !$v.docData.description.$error : null"
                       :disabled="disabled || !isAdmin"
      ></b-form-textarea>
    </b-form-group>
    <b-form-group label="Дата изменения" v-if="!creating">
      <b-form-input type="date" v-model="lastModifiedDate"
                    :disabled="disabled || !isAdmin"
      ></b-form-input>
      <b-form-input type="time" step="1" v-model="lastModifiedTime"
                    :disabled="disabled || !isAdmin"
      ></b-form-input>
    </b-form-group>
    <b-form-group label="Приложенные файлы">
      <b-tabs content-class="mt-2">
        <b-tab title="Файлы">
          <div v-for="(att, index) in fileAttachments" class="d-flex" v-bind:key="att.id">
            <b-form-file accept="audio/*, application/*, video/*, text/*, font/*, model/*, message/*, multipart/*"
                         class="my-2" v-model="att.fileBlob"></b-form-file>
            <b-button v-if="isAdmin" @click="deleteFileAttachment(index)" title="Удалить" class="my-2 ml-2">
              <b-icon-trash font-scale="0.75"></b-icon-trash font-scale="0.75" />
            </b-button>
          </div>
          <b-button v-if="isAdmin" class="mb-2 w-100" @click="addFileAttachment" variant="primary">Добавить приложенный файл</b-button>
          <b-list-group v-if="existingFileAttachments != null && existingFileAttachments.length > 0">
            <b-list-group-item v-for="att in existingFileAttachments" v-bind:key="att.path"
                               class="d-flex align-items-center">
              {{ att.path }}
              <a class="ml-auto" title="Скачать" :href="`/api/doc-sync/files/download/${att.id}_${att.path}`" download>
                <b-icon-download/>
              </a>
              <b-button v-if="isAdmin" class="ml-1" title="Удалить" @click="deleteAttachment(att)">
                <b-icon-trash font-scale="0.75"/>
              </b-button>
            </b-list-group-item>
          </b-list-group>
        </b-tab>
        <b-tab title="Изображения">
          <b-row cols="1" cols-sm="2" cols-md="3" cols-lg="4" class="mx-0">
            <b-card class="col" body-class="d-flex flex-column" v-for="att in existingImageAttachments"
                    v-bind:key="att.path">
              <div v-if="att.urlValue" class="img-wrapper position-relative d-flex">
                <img :src="att.urlValue">
                <div v-if="isAdmin" title="Удалить" class="del-img-btn mx-2" @click="deleteAttachment(att)">
                  <b-icon icon="x-circle" scale="2" variant="danger"></b-icon>
                </div>
              </div>
              <template v-if="att.path">
                <div class="img-wrapper position-relative d-flex">
                  <img :src="`/api/doc-sync/files/download/${att.id}_${att.path}`">
                  <div v-if="isAdmin" title="Удалить" class="del-img-btn mx-2" @click="deleteAttachment(att)">
                    <b-icon icon="x-circle" scale="2" variant="danger"></b-icon>
                  </div>
                </div>
                <div class="pt-1 mt-auto d-flex align-items-center">
                  {{ att.path }}
                  <a class="ml-auto" title="Скачать" :href="`/api/doc-sync/files/download/${att.id}_${att.path}`"
                     download>
                    <b-icon-download/>
                  </a>
                </div>
              </template>
              <div class="pt-2 mt-auto" v-else-if="isAdmin">
                <b-form-file accept="image/*" @input="loadImageFromAttachment(att)"
                             v-model="att.fileBlob"></b-form-file>
              </div>
            </b-card>
            <b-card v-if="isAdmin" class="col" @click="addImageAttachment">
              <b-icon class="img-add-btn" title="Добавить приложенное изображение" icon="plus-circle"></b-icon>
            </b-card>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-form-group>
    <b-button v-if="isAdmin" class="w-100" type="submit" :disabled="disabled || submitting || $v.docData.$anyError">
      Сохранить
    </b-button>
    <b-button v-else class="w-100" type="submit" v-bind:to="'/'" :disabled="disabled || submitting || $v.docData.$anyError">
      Назад
    </b-button>
    <b-alert class="mt-2" variant="info" :show="submitting">
      Сохранение...
    </b-alert>
  </b-form>
  <div v-else>

  </div>
</template>
<style>
.img-wrapper img {
  max-width: 100%;
  height: auto;
}

.del-img-btn {
  height: min-content;
  cursor: pointer;
}

.img-add-btn {
  display: block;
  width: 100%;
  height: auto;
  cursor: pointer;
}
</style>
<script>
import {required, helpers} from "vuelidate/lib/validators";
import document from '@/modules/document';
import {BIconDownload, BIconTrash} from 'bootstrap-vue';
import moment from 'moment';
import {v4 as uuidv4} from 'uuid';
import {mapGetters} from "vuex";

const guid = helpers.regex('guid', /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/)
export default {
  components: {
    'b-icon-download': BIconDownload,
    'b-icon-trash': BIconTrash
  },
  mounted() {
    if (this.creating) {
      //создаём сами
      this.docData = {
        id: null,
        price: null,
        name: null,
        description: null
      };
    } else if (this.$route.params.id != null) {
      this.reloadDocData(this.$route.params.id);
    }
  },
  data() {
    return {
      submitting: false,
      attachmentTypes: [{
        value: 'DEFAULT', text: 'Обычный файл'
      }, {
        value: 'IMAGE', text: 'Изображение'
      }],
      fileAttachments: [],
      imageAttachments: [],
      docData: null,
    };
  },
  props: {
    creating: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  validations() {
    let docDataValidation = {
      id: {
        required,
        guid
      },
      name: {
        required
      },
      description: {},
      price: {
        required
      }
    };
    if (!this.creating) {
      docDataValidation.lastModified = {
        required
      };
    }
    return {
      docData: docDataValidation
    };
  },
  computed: {
    ...mapGetters([
       "isAdmin"
    ]),
    lastModifiedDate: {
      get() {
        if (this.docData == null || this.docData.lastModified == null) {
          return '';
        } else {
          return moment.parseZone(this.docData.lastModified).format('YYYY-MM-DD');
        }
      },
      set(value) {
        if (this.docData == null) {
          return;
        } else if (this.docData.lastModified == null) {
          this.docData.lastModified = moment(value).format('YYYY-MM-DDTHH-mm-ssZ');
        } else {
          let newDate = moment(value, 'YYYY-MM-DD');
          let momentVal = moment.parseZone(this.docData.lastModified)
              .year(newDate.year())
              .month(newDate.month())
              .date(newDate.date());//угу это день месяца, без доков не понять
          this.docData.lastModified = momentVal.format();
        }
      }
    },
    lastModifiedTime: {
      get() {
        if (this.docData == null || this.docData.lastModified == null) {
          return '';
        } else {
          return moment(this.docData.lastModified, 'YYYY-MM-DDTHH-mm-ssZ').format('HH:mm:ss');
        }
      },
      set(time) {
        if (this.docData?.lastModified != null) {
          let newDate = moment(time, 'HH:mm:ss');
          let momentVal = moment(this.docData.lastModified)
              .second(newDate.second())
              .minute(newDate.minute())
              .hour(newDate.hour());
          this.docData.lastModified = momentVal.format();
        }
      },
    },
    existingImageAttachments() {
      return this.docData?.attachments == null ? this.imageAttachments : this.docData?.attachments.filter(att => att.type === 'IMAGE').concat(this.imageAttachments);
    },
    existingFileAttachments() {
      return this.docData?.attachments?.filter(att => att.type === 'DEFAULT');
    }
  },
  methods: {
    onSubmit() {
      this.$v.docData.$touch();
      if (this.submitting || this.$v.docData.$anyError) {
        return;
      }
      this.submitting = true;
      //TODO: убрать аттачи если они уже загрузились
      let attachmentsToUpload = this.fileAttachments
          .filter(att => att.fileBlob != null).map(att => {
            return {
              id: att.id,
              fileBlob: att.fileBlob,
              attachmentType: 'DEFAULT'
            };
          })
          .concat(this.imageAttachments.filter(att => att.fileBlob != null).map(att => {
            return {
              id: att.id,
              fileBlob: att.fileBlob,
              attachmentType: 'IMAGE'
            };
          }));
      if (this.creating) {
        document.createDocument(this.docData)
            .then(resp => {
              this.docData.lastModified = resp.data.lastModified;
              this.docData.id = resp.data.id;
            }).catch((error) => {
              let errorMessage;
              if (error.isAxiosError) {
                errorMessage = `Ошибка при создании документа (код ${error.response?.status})`;
              } else {
                errorMessage = 'Произошла неизвестная ошибка при создании документа';
              }
              this.$bvToast.toast(errorMessage, {
                title: 'Ошибка',
                autoHideDelay: 5000,
                appendToast: true
              });

              throw error;
            })
            .then(() => {
              if (attachmentsToUpload.length > 0) {
                return Promise.all(attachmentsToUpload.map(att => {
                  return document
                      .uploadFile({
                        documentId: this.docData.id,
                        attachmentId: att.id,
                        attachmentType: att.attachmentType
                      }, att.fileBlob)
                      .then(resp => {
                        if (this.docData.attachments == null) {
                          this.docData.attachments = [];
                        }// так то можно и не пихать
                        this.docData.attachments.push({
                          path: resp.data.name,
                          id: att.id,
                          type: att.attachmentType
                        });
                      });
                }));
              }
            })
            .then(() => {
              this.$router.push(`/${this.docData.id}`);
            })
            .catch((e) => {
              if (e.isAxiosError) {
                if (e.response.status === 413) {
                  this.$bvToast.toast('Превышен допустимый размер изображений при загрузке', {
                    title: 'Ошибка',
                    autoHideDelay: 5000,
                    appendToast: true
                  });
                } else {
                  this.$bvToast.toast('Возникла неизвестная ошибка загрузке', {
                    title: 'Ошибка',
                    autoHideDelay: 5000,
                    appendToast: true
                  });
                }
              }

              throw e;
            })
            .finally(() => {
              this.submitting = false;
            });
      } else {// если товар есть то лучше сразу слать данные
        let prevTask;
        if (attachmentsToUpload.length !== 0) {
          prevTask = Promise.all(attachmentsToUpload.map(att => {
            return document
                .uploadFile({
                  documentId: this.docData.id,
                  attachmentId: att.id,
                  attachmentType: att.attachmentType
                }, att.fileBlob)
                .then(resp => {
                  if (this.docData.attachments == null) {
                    this.docData.attachments = [];
                  }
                  this.docData.attachments.push({
                    path: resp.data.name,
                    id: att.id,
                    type: att.attachmentType
                  });
                })
          }));
        } else {
          prevTask = Promise.resolve(true);
        }
        prevTask
            .then(() => {
              return document.updateDocument(this.docData)
            })
            .then(resp => {
              this.$router.push('/');
            }, e => {
              if (error.isAxiosError) {
                if (e.response?.status === 413) {
                  errorMessage = 'Превышен допустимый размер изображений';
                } else {
                  errorMessage = `Ошибка при обновлении документа (код ${error.response?.status})`;
                }
              } else {
                errorMessage = 'Произошла неизвестная ошибка при обновлении документа';
              }
              this.$bvToast.toast(errorMessage, {
                title: 'Ошибка',
                autoHideDelay: 5000,
                appendToast: true
              });

              throw e;
            })
            .finally(() => {
              this.submitting = false;
            });
      }
    },
    deleteFileAttachment(index) {
      this.fileAttachments.splice(index, 1);
    },
    deleteImageAttachment(index) {
      this.imageAttachments.splice(index, 1);
    },
    addFileAttachment() {
      this.fileAttachments.push({
        id: uuidv4(),
        fileBlob: null
      });
    },
    addImageAttachment() {
      this.imageAttachments.push({
        id: uuidv4(),
        fileBlob: null,
        urlValue: null
      });
    },
    loadImageFromAttachment(att) {
      const fr = new FileReader();
      fr.onload = function () {
        att.urlValue = fr.result;
      }
      fr.readAsDataURL(att.fileBlob);
    },
    deleteAttachment(att) {
      if (att.fileBlob) { // для загружаемых аттачей ничего не спрашивать
        this.imageAttachments = this.imageAttachments.filter(attObj => att.id !== attObj.id);
        return;
      }
      this.$bvModal
          .msgBoxConfirm('Вы уверены, что хотите удалить вложение?', {
            title: 'Удаление',
            titleTag: 'h6',
            okVariant: 'danger',
            okTitle: 'Удалить',
            cancelTitle: 'Отмена',
            cancelVariant: 'outline-secondary',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          })
          .then(value => {
            if (value) {
              document.deleteAttachment(att.id, att.path)
                  .then(() => {
                    if (this.docData.attachments != null && this.docData.attachments.length > 0) {
                      this.docData.attachments = this.docData.attachments.filter(attObj => attObj.path !== att.path);
                    }
                  });
            }
          });
    },
    generateDocId() {
      if (this.docData == null) {
        return;
      }
      this.docData.id = uuidv4();
    },
    reloadDocData(userId) {
      return document.getSingle(userId)
          .then(resp => {
            this.docData = resp.data;
            document.title = this.$route.meta.title + ' ' + resp.data.name;
            return Promise.resolve(this.docData);
          });
    }
  }

}
</script>
