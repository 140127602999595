<template>
  <div v-if="isRolesEmpty">
    <h1 class="my-4">У вас нет доступа к данному сервису</h1>
  </div>
  <div v-else>
    <div class="d-flex">
      <router-link v-if="isAdmin" to="/create" class="btn btn-primary mb-2">Создать документ</router-link>
      <b-form-checkbox class="ml-auto d-inline-block" title="Не показывать удалённые товары" v-model="filterDeleted">Не
        показывать удалённые документы
      </b-form-checkbox>
    </div>
    <b-table
        hover
        :items="getDocumentDataTable"
        :fields="fields"
        ref="document_table"
        :per-page="documentList.perPage"
        class="table-bordered table-hover"
        show-empty>
      <template v-slot:empty="scope">
        <h6 class="text-center">Документы отсутствуют</h6>
      </template>
      <template v-slot:cell(id)="row">
        {{ row.item.id }}
      </template>
      <template v-slot:cell(name)="row">
        {{ (row.item.name != null && row.item.name != "") ? row.item.name : ('ID: ' + row.item.id) }}
      </template>
      <template v-slot:cell(deleted)="row">
        {{ (row.item.deleted) ? 'Да' : 'Нет' }}
      </template>
      <template v-slot:cell(price)="row">
        {{ (row.item.price != null && row.item.name != "") ? row.item.price : '' }}
      </template>
      <template v-slot:cell(interaction)="row">
        <template v-if="!row.item.deleted">
          <b-button class="" variant="primary"
                    :title="isAdmin ? 'Редактирование' : 'Просмотр'"
                    @click="prepareDetailPage(row.item)"
          >
            <b-icon-pencil v-if="isAdmin" font-scale="0.75"></b-icon-pencil>
            <b-icon-eye v-else font-scale="0.75"></b-icon-eye>
          </b-button>
          <b-button v-if="isAdmin" class="ml-1" variant="primary"
                    title="Удалить"
                    @click="deleteDocument(row.item)"
          >
            <b-icon-trash font-scale="0.75"></b-icon-trash>
          </b-button>
        </template>
      </template>
    </b-table>
    <div class="d-flex">
      <div class="col-md-2 pl-0">
        <b-form-select
            v-model="documentList.perPage"
            class="text-light border-secondary bg-secondary"
        >
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
        </b-form-select>
      </div>
      <span class="align-self-center">записей на страницу</span>
      <b-pagination
          v-model="documentList.currentPage"
          class="my-0 ml-auto"
          :total-rows="documentList.totalCount"
          :per-page="documentList.perPage"
          aria-controls="template-table"
          @input="getDocumentDataTable"
      ></b-pagination>
    </div>
  </div>
</template>
<script>
import docApi from '@/modules/document';
import {BIconPencil, BIconPlus, BIconTrash, BIconDash} from 'bootstrap-vue';
import {mapGetters} from "vuex";

export default {
  name: 'user-list',
  components: {
    'b-icon-pencil': BIconPencil,
    'b-icon-plus': BIconPlus,
    'b-icon-dash': BIconDash,
    'b-icon-trash': BIconTrash
  },
  watch: {
    filterDeleted(newValue) {
      this.$refs.document_table.refresh();
    }
  },
  data() {
    return {
      fields: [
        {
          key: 'id', label: 'Идентификатор'
        },
        {
          key: 'name', label: 'Имя'
        },
        {
          key: 'price', label: 'Цена'
        },
        {
          key: 'deleted', label: 'Удален'
        },
        {
          key: 'interaction', label: 'Взаимодействие'
        }
      ],
      filterDeleted: false,
      documentList: {
        totalCount: 0,
        perPage: 20,
        currentPage: 1
      },
      selectedUserId: null
    };
  },
  computed: {
    ...mapGetters([
        "isAdmin",
        "isRolesEmpty"
    ])
  },
  methods: {
    getDocumentDataTable(context) {
      return docApi.getDocumentList((context.currentPage - 1) * context.perPage,
          context.perPage, this.filterDeleted ? false : null).then(resp => {
        this.documentList.totalCount = resp.data.totalCount;
        return resp.data.content;
      }).catch((error) => {
        let errorMessage;
        if (error.isAxiosError) {
          errorMessage = `Ошибка при запросе списка публикаций (код ${error.response?.status})`;
        } else {
          errorMessage = 'Произошла неизвестная ошибка при запросе списка публикаций';
        }
        this.$bvToast.toast(errorMessage, {
          title: 'Ошибка',
          autoHideDelay: 5000,
          appendToast: true
        });

        throw error;
      });
    },
    deleteDocument(document) {
      let docId = document.id;
      //запуск модального окна
      this.$bvModal
          .msgBoxConfirm('Вы уверены, что хотите удалить документ?', {
            title: 'Удаление',
            titleTag: 'h6',
            okVariant: 'danger',
            okTitle: 'Удалить',
            cancelTitle: 'Отмена',
            cancelVariant: 'outline-secondary',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          })
          .then(value => {
            console.debug('value', value, 'docid', docId)
            if (value) {
              docApi.deleteDocument(docId)
                  .then(response => {
                    this.deleteError = null;
                    return this.$refs.document_table.refresh();
                  })
                  .catch(error => {
                    this.deleteError = error;
                  });
            }
          })
          .catch(err => {
            // An error occurred
            this.deleteError = err;
          });
    },
    prepareDetailPage(document) {
      this.$router.push(`/${document.id}`);
    }
  }
}
</script>
